import React, { useEffect } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import './style.css'

const Header = () => {
    const location = useLocation();
    useEffect(() => {
        const header = document.querySelector('.header');

        const handleScroll = () => {
            const scrollY = window.scrollY;

            if (scrollY > 0.1 * parseFloat(getComputedStyle(document.documentElement).fontSize)) {
                header.classList.add('fixed-header')
            } else {
                header.classList.remove('fixed-header')
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [location.pathname])
    return (
        <header className="header">
            <div className="logo">Edson Luiz</div>
            <nav>
                <ul className="nav-list">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/projects">Projects</Link></li>
                </ul>
            </nav>
            <Outlet />
        </header>
    )
}
export default Header;