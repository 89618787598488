import React from 'react';
import "./style.css";
const Footer = () => {
    return (
        <footer className="footer">
            <p>© 2023 Edson Luiz</p>
        </footer>
    )
}

export default Footer;
