
import React from 'react';
import "./style.css"

const Home = () => {
    return (
        <div className="home">
            <div class="text-center">
                <h1>Constantly evolving to offer the best. Follow my journey as an ITSM Specialist and Front End Developer as I turn ideas into reality.</h1>
            </div>
            <div class="container">
                <h1>Edson Luiz dos Santos Junior</h1>
                <h2>System Administrator/Developer | ITSM & CSM Specialist, knowledge in Javascript, HTML, CSS | Sysaid Expert</h2>
                <div class="contact-info">
                    <p>Location: London, England, United Kingdom</p>
                    <p>Phone: <a href="tel:+447754540377">+44 7754540377</a></p>
                    <p>Email: <a href="mailto:edsonluiz.uk@gmail.com">edsonluiz.uk@gmail.com</a></p>
                    <p>LinkedIn: <a href="www.linkedin.com/in/edsonlsj">www.linkedin.com/in/edsonlsj</a></p>
                </div>
                <div class="summary">
                    <p>Professional specialized in Systems Administration and Development, with a primary focus on the strategic configuration of ITSM/CSM systems for performance optimization. My experience encompasses form design, process optimization, and workflow using HTML, CSS, JavaScript, along with advanced Project Management practices and expertise in Business Analysis. I have a comprehensive understanding of ITIL practices and expertise in platforms such as ServiceNow, Azure, and Microsoft 365.</p>
                </div>
            </div>
        </div>
    );
};

export default Home;
