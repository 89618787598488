import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./App.css"

import Header from './components/Header';
import Footer from './components/Footer';

import Projects from './pages/Projects';
import About from './pages/About';
import Home from './pages/Home/Home';


const App = () => {
  return (
    <>
      <BrowserRouter>
        <div className="grid-container">
          <Header />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="projects" element={<Projects />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
};

export default App;

