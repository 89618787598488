import React from 'react';

const Projects = () => {
    return (
        <div>
            <h1>Projects</h1>
        </div>
    );
};

export default Projects;